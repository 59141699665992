<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info='info'></el-swiper>
    <div class="main">
      <div class="part part1">
        <div class="title">Own Private Platform</div>
        <div class="bgimg"></div>
        <div class="innerTitle">三大平台</div>
        <ul class="card_ul">
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/sycl.png"
                 alt="">
            <p class="title_">尚盈车联</p>
            <p class="title_dsc">城市智慧停车整体解决方案</p>
          </li>
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/lxxp.png"
                 alt="">
            <p class="title_">乐享小铺</p>
            <p class="title_dsc">智慧数字经营小程序</p>
          </li>
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/symp.png"
                 alt="">
            <p class="title_">尚盈名品</p>
            <p class="title_dsc">综合电商集采平台</p>
          </li>
        </ul>
      </div>

    </div>
    <div class="container container1">
      <div class="main">
        <div class="part part2">
          <div class="title">Platform Advantage</div>
          <div class="bgimg"></div>
          <div class="innerTitle">平台优势</div>
          <ul class="card_ul">
            <li class="card_li">
              <img class="img_logo"
                   src="../assets/images/company.png"
                   alt="">
              <p class="title_">上市集团公司</p>
              <p class="explain">（股票代码：837274）</p>
              <div class="margin_bottom_">
                <p class="title_dsc1">实力强劲 技术雄厚</p>
                <p class="title_dsc2">百人研发团队倾力打造</p>
              </div>
            </li>
            <li class="card_li">
              <img class="img_logo"
                   src="../assets/images/service.png"
                   alt="">
              <p class="title_">三大数字服务</p>
              <div class="margin_bottom_">
                <p class="title_dsc1">构成城市整体生态圈</p>
                <p class="title_dsc2">实现客户快速增长导流</p>
              </div>
            </li>
            <li class="card_li">
              <img class="img_logo"
                   src="../assets/images/platform.png"
                   alt="">
              <p class="title_">一体化平台</p>
              <div class="margin_bottom_">
                <p class="title_dsc1">深度分析 标准化智能管理</p>
                <p class="title_dsc2">助力经营 营销运营指导</p>
              </div>
            </li>
            <li class="card_li">
              <img class="img_logo"
                   src="../assets/images/teamwork.png"
                   alt="">
              <p class="title_">多种合作模式</p>
              <div class="margin_bottom_">
                <p class="title_dsc1">政府、停车场、零售、个人</p>
                <p class="title_dsc2">加盟入驻 招商合伙</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="part part3">
        <div class="title">Easy To Get Gains</div>
        <div class="bgimg"></div>
        <div class="innerTitle">降本增益</div>
        <ul class="card_ul">
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/part3_1.png"
                 alt="">
            <p class="title_">生态引流</p>
            <div class="bottom_text">
              <p class="title_dsc1">协助集客锁客</p>
              <p class="title_dsc2">助力扩大流量池</p>
            </div>
          </li>
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/part3_2.png"
                 alt="">
            <p class="title_">业绩提升</p>
            <div class="bottom_text">
              <p class="title_dsc1">协助集客锁客</p>
              <p class="title_dsc2">助力扩大流量池</p>
            </div>
          </li>
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/part3_3.png"
                 alt="">
            <p class="title_">业务拓展</p>
            <div class="bottom_text">
              <p class="title_dsc1">协助集客锁客</p>
              <p class="title_dsc2">助力扩大流量池</p>
            </div>
          </li>
          <li class="card_li">
            <img class="img_logo"
                 src="../assets/images/part3_4.png"
                 alt="">
            <p class="title_">降本增润</p>
            <div class="bottom_text">
              <p class="title_dsc1">协助集客锁客</p>
              <p class="title_dsc2">助力扩大流量池</p>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <!-- <div class="container container2">
      <div class="main">
        <div class="part part4">
          <div class="title">News And Trends</div>
          <div class="bgimg"></div>
          <div class="innerTitle">新闻动态</div>
          <ul class="card_ul">
            <li class="card_li"
                v-for="item in newsList"
                :key='item.title'>
              <img class="news_img"
                   :src="item.imgUrl"
                   alt="">
              <div class="text_info">
                <div class="title">{{ item.title }}</div>
                <div class="content">{{ item.text }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      newsList: [
        { imgUrl: '', title: '新闻标题新闻标题新闻标题', time: '2019-12-20', text: '新闻内容新闻内容新闻新闻内容新闻新闻内容新闻新闻内容新闻新闻内容新闻内容新闻内容新闻 内容新闻内容新闻内容新闻……' },
        { imgUrl: '', title: '新闻标题新闻标题新闻标题1', time: '2019-12-20', text: '新闻内容新闻内容新闻内容新闻内容新闻 内容新闻内容新闻内容新闻……' },
        { imgUrl: '', title: '新闻标题新闻标题新闻标题2', time: '2019-12-20', text: '新闻内容新闻内容新闻内容新闻内容新闻 内容新闻内容新闻内容新闻……' },
        { imgUrl: '', title: '新闻标题新闻标题新闻标题3', time: '2019-12-20', text: '新闻内容新闻内容新闻内容新闻内容新闻 内容新闻内容新闻内容新闻……' }
      ],
      imgSrc: require('@/assets/images/banner1.png'),
      info: {
        title: '尚盈期颐',
        dsc: '智慧城市生活圈 专业IT技术服务商',
        number: '中盈高科（股票代码：837274）旗下全资子公司'
      }
    };
  },
  created () { },
  mounted () { },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/home.scss";
</style>
